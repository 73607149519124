<template>
  <v-container fluid grid-list-md>
    <v-layout row wrap>
      <v-flex xs12>
         <div id="titolo" class="display-1 mb-5 primary--text">Dashboard</div>
         <div class="title mb-3">Anagrafica</div>
      </v-flex>
      <v-flex xs12 lg3 md6 sm6 v-for="box in boxes.slice(0,4)" :key="box.id">
        <v-card
          v-if="box.id === 0"
          @click.native="box.route ? $router.push({ name: box.route }) : ''"
          :dark="box.hover"
          :color="box.hover ? 'primary' : ''"
          @mouseover="box.hover = true"
          @mouseleave="box.hover = false"
          :class="box.hover ? 'white--text' : ''">
          <v-container fluid grid-list-lg>
            <v-layout row>
              <v-flex xs9>
                <div>
                  <h3 class="title"><v-icon medium>{{box.icon}}</v-icon> {{box.name}}</h3>
                </div>
              </v-flex>
              <v-flex xs3 d-flex>
                <h3 class="display-1 text-xs-right">{{box.value}}</h3>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
        <!--/ tipo 0 -->
        <v-card
          v-if="box.id === 1"
          @click.native="box.route ? $router.push({ name: box.route }) : ''"
          :dark="box.hover"
          :color="box.hover ? 'primary' : ''"
          @mouseover="box.hover = true"
          @mouseleave="box.hover = false"
          :class="box.hover ? 'white--text' : ''">
          <v-container fluid grid-list-lg>
            <v-layout row>
              <v-flex xs9>
                <div>
                  <h3 class="title"><v-icon medium>{{box.icon}}</v-icon> {{box.name}}</h3>
                </div>
              </v-flex>
              <v-flex xs3 d-flex>
                <h3 class="display-1 text-xs-right">{{box.value}}</h3>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
        <!--/ tipo 1 -->
        <v-card
          v-if="box.id === 2"
          @click.native="box.route ? $router.push({ name: box.route }) : ''"
          :dark="box.hover"
          :color="box.hover ? 'primary' : ''"
          @mouseover="box.hover = true"
          @mouseleave="box.hover = false"
          :class="box.hover ? 'white--text' : ''">
          <v-container fluid grid-list-lg>
            <v-layout row>
              <v-flex xs9>
                <div>
                  <h3 class="title"><v-icon medium>{{box.icon}}</v-icon> {{box.name}}</h3>
                </div>
              </v-flex>
              <v-flex xs3 d-flex>
                <h3 class="display-1 text-xs-right">{{box.value}}</h3>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
        <!--/ tipo 2 -->
        <v-card
          v-if="box.id === 3"
          @click.native="box.route ? $router.push({ name: box.route }) : ''"
          :dark="box.hover"
          :color="box.hover ? 'primary' : ''"
          @mouseover="box.hover = true"
          @mouseleave="box.hover = false"
          :class="box.hover ? 'white--text' : ''">
          <v-container fluid grid-list-lg>
            <v-layout row>
              <v-flex xs9>
                <div>
                  <h3 class="title"><v-icon medium>{{box.icon}}</v-icon> {{box.name}}</h3>
                </div>
              </v-flex>
              <v-flex xs3 d-flex>
                <h3 class="display-1 text-xs-right">{{box.value}}</h3>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
        <!--/ tipo 3 -->
      </v-flex>

      <v-flex xs12>
         <div class="title mt-5 mb-3">Produzione</div>
      </v-flex>

      <v-flex xs12 lg6 md12 sm12>
        <v-card
          @click.native="boxes[6].route ? $router.push({ name: boxes[6].route }) : ''"
          :dark="boxes[6].hover"
          :color="boxes[6].hover ? 'primary' : ''"
          @mouseover="boxes[6].hover = true"
          @mouseleave="boxes[6].hover = false"
          :class="boxes[6].hover ? 'white--text' : ''">
          <v-container fluid grid-list-lg>
            <v-layout row>
              <v-flex xs12 lg6 md12 sm12>
                <div>
                  <h3 class="display-1 text-xs-center">{{boxes[6].value}}</h3>
                  <h3 class="title text-xs-center">{{boxes[6].name}}</h3>
                </div>
              </v-flex>
              <v-flex xs12 lg6 md12 sm12>
                <span class="title text-xs-left">{{ getBoxParam(4, 'name') }}</span> <span class="display-1 pl-5 ml-2">{{ getBoxParam(4, 'value') }}</span>
                <br />
                <span class="title text-xs-left">{{ getBoxParam(5, 'name') }}</span> <span class="display-1 pl-5 ">{{ getBoxParam(5, 'value') }}</span>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
        <!--/ tipo 12 + 13-->
      </v-flex>

      <v-flex xs12 xl3 md6 sm6 v-for="box in boxes.slice(7,8)" :key="box.id">
        <v-card
          v-if="box.id === 7"
          @click.native="box.route ? $router.push({ name: box.route }) : ''"
          :dark="box.hover"
          :color="box.hover ? 'primary' : ''"
          @mouseover="box.hover = true"
          @mouseleave="box.hover = false"
          :class="box.hover ? 'white--text' : ''">
          <v-container fluid grid-list-lg>
            <v-layout row>
              <v-flex xs9>
                <div>
                  <h3 class="title pt-4 pb-4"><v-icon medium>{{box.icon}}</v-icon> {{box.name}}</h3>
                </div>
              </v-flex>
              <v-flex xs3 d-flex>
                <h3 class="display-1 pt-4 text-xs-right">{{box.value}}</h3>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
        <!--/ tipo 7 -->
      </v-flex>

      <v-flex xs12>
         <div class="title mt-5 mb-3">Magazzino</div>
      </v-flex>

       <v-flex xs12 xl6 md6 sm12 v-for="box in boxes.slice(8,10)" :key="box.id">
        <v-card
          v-if="box.id === 8"
          @click.native="box.route ? $router.push({ name: box.route }) : ''"
          :dark="box.hover"
          :color="box.hover ? 'primary' : ''"
          @mouseover="box.hover = true"
          @mouseleave="box.hover = false"
          :class="box.hover ? 'white--text' : ''">
          <v-container fluid grid-list-lg>
            <v-layout row>
              <v-flex xs9>
                <div>
                  <h3 class="title"><v-icon medium>{{box.icon}}</v-icon> {{box.name}}</h3>
                </div>
              </v-flex>
              <v-flex xs3 d-flex>
                <h3 class="display-1 text-xs-right">{{box.value}}</h3>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
        <!--/ tipo 8 -->
        <v-card
          v-if="box.id === 9"
          @click.native="box.route ? $router.push({ name: box.route }) : ''"
          :dark="box.hover"
          :color="box.hover ? 'primary' : ''"
          @mouseover="box.hover = true"
          @mouseleave="box.hover = false"
          :class="box.hover ? 'white--text' : ''">
          <v-container fluid grid-list-lg>
            <v-layout row>
              <v-flex xs9>
                <div>
                  <h3 class="title"><v-icon medium>{{box.icon}}</v-icon> {{box.name}}</h3>
                </div>
              </v-flex>
              <v-flex xs3 d-flex>
                <h3 class="display-1 text-xs-right">{{box.value}}</h3>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
        <!--/ tipo 9 -->
      </v-flex>

      <v-flex xs12 xl3 md3 sm6>
        <v-card
          @click.native="boxes[10].route ? $router.push({ name: boxes[10].route }) : ''"
          :dark="boxes[10].hover"
          :color="boxes[10].hover ? 'primary' : ''"
          @mouseover="boxes[10].hover = true"
          @mouseleave="boxes[10].hover = false"
          :class="boxes[10].hover ? 'white--text' : ''">
          <v-container fluid grid-list-lg>
            <h3 class="title mb-3">Bancali RBA</h3>
            <v-layout row>
              <v-flex xs9>
                <div>
                  <h3 class="subheading">{{ boxes[10].name }}</h3>
                  <h3 class="subheading">{{ getBoxParam(11, 'name') }}</h3>
                  <h3 class="subheading pt-3">Totale Bancali RBA</h3>
                </div>
              </v-flex>
              <v-flex xs3>
                <h3 class="title text-xs-right">{{boxes[10].value}}</h3>
                <h3 class="title text-xs-right">{{ getBoxParam(11, 'value') }}</h3>
                <h3 class="title text-xs-right pt-3">{{boxes[10].value + getBoxParam(11, 'value')}}</h3>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
        <!--/ tipo 10 + 11-->
      </v-flex>

      <v-flex xs12 xl3 md3 sm6>
        <v-card
          @click.native="boxes[12].route ? $router.push({ name: boxes[12].route }) : ''"
          :dark="boxes[12].hover"
          :color="boxes[12].hover ? 'primary' : ''"
          @mouseover="boxes[12].hover = true"
          @mouseleave="boxes[12].hover = false"
          :class="boxes[12].hover ? 'white--text' : ''">
          <v-container fluid grid-list-lg>
            <h3 class="title mb-3">Bancali Raffi</h3>
            <v-layout row>
              <v-flex xs9>
                <div>
                  <h3 class="subheading">{{ boxes[12].name }}</h3>
                  <h3 class="subheading">{{ getBoxParam(13, 'name') }}</h3>
                  <h3 class="subheading pt-3">Totale Bancali Raffi</h3>
                </div>
              </v-flex>
              <v-flex xs3>
                <h3 class="title text-xs-right">{{boxes[12].value}}</h3>
                <h3 class="title text-xs-right">{{ getBoxParam(13, 'value') }}</h3>
                <h3 class="title text-xs-right pt-3">{{boxes[12].value + getBoxParam(13, 'value')}}</h3>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
        <!--/ tipo 12 + 13-->
      </v-flex>

      <v-flex xs12 xl3 md3 sm6>
        <v-card
          @click.native="boxes[14].route ? $router.push({ name: boxes[14].route }) : ''"
          :dark="boxes[14].hover"
          :color="boxes[14].hover ? 'primary' : ''"
          @mouseover="boxes[14].hover = true"
          @mouseleave="boxes[14].hover = false"
          :class="boxes[14].hover ? 'white--text' : ''">
          <v-container fluid grid-list-lg>
            <h3 class="title mb-3">Bancali Panini</h3>
            <v-layout row>
              <v-flex xs9>
                <div>
                  <h3 class="subheading">{{ boxes[14].name }}</h3>
                  <h3 class="subheading">{{ getBoxParam(15, 'name') }}</h3>
                  <h3 class="subheading pt-3">Totale Bancali Panini</h3>
                </div>
              </v-flex>
              <v-flex xs3>
                <h3 class="title text-xs-right">{{boxes[14].value}}</h3>
                <h3 class="title text-xs-right">{{ getBoxParam(15, 'value') }}</h3>
                <h3 class="title text-xs-right pt-3">{{boxes[14].value + getBoxParam(15, 'value')}}</h3>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
        <!--/ tipo 14 + 15-->
      </v-flex>

      <v-flex xs12 xl3 md3 sm6>
        <v-card
          @click.native="boxes[16].route ? $router.push({ name: boxes[16].route }) : ''"
          :dark="boxes[16].hover"
          :color="boxes[16].hover ? 'primary' : ''"
          @mouseover="boxes[16].hover = true"
          @mouseleave="boxes[16].hover = false"
          :class="boxes[16].hover ? 'white--text' : ''">
          <v-container fluid grid-list-lg>
            <h3 class="title mb-3">Bancali Panini Milano</h3>
            <v-layout row>
              <v-flex xs9>
                <div>
                  <h3 class="subheading">{{ boxes[16].name }}</h3>
                  <h3 class="subheading">{{ getBoxParam(17, 'name') }}</h3>
                  <h3 class="subheading pt-3">Totale Bancali Panini</h3>
                </div>
              </v-flex>
              <v-flex xs3>
                <h3 class="title text-xs-right">{{boxes[16].value}}</h3>
                <h3 class="title text-xs-right">{{ getBoxParam(17, 'value') }}</h3>
                <h3 class="title text-xs-right pt-3">{{boxes[16].value + getBoxParam(17, 'value')}}</h3>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
        <!--/ tipo 16 + 17-->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { $EventBus } from '@/eventBus'

export default {
  data () {
    return {
      boxes: [
        { id: 0, icon: 'person', name: 'Clienti', value: 0, route: 'clienti' },
        { id: 1, icon: 'layers', name: 'Tipi lavorazione', value: 0, route: 'tipiLavorazione' },
        { id: 2, icon: 'apps', name: 'Componenti', value: 0, route: 'componenti' },
        { id: 3, icon: 'local_shipping', name: 'Corrieri', value: 0, route: 'corrieri' },
        { id: 4, icon: 'shopping_basket', name: 'Ordini nuovi', value: 0, route: 'ordini' },
        { id: 5, icon: 'shopping_basket', name: 'Ordini spediti', value: 0, route: 'ordini' },
        { id: 6, icon: 'shopping_basket', name: 'Ordini totali', value: 0, route: 'ordini' },
        { id: 7, icon: 'insert_drive_file', name: 'DDT', value: 0, route: 'ddts' },
        { id: 8, icon: 'archive', name: 'Bancali totali a Cormano', value: 0, route: 'gestione' },
        { id: 9, icon: 'archive', name: 'Bancali totali a Paderno', value: 0, route: 'gestione' },
        { id: 10, icon: 'archive', name: 'Bancali RBA Cormano', value: 0, route: 'gestione' },
        { id: 11, icon: 'archive', name: 'Bancali RBA Paderno', value: 0, route: 'gestione' },
        { id: 12, icon: 'archive', name: 'Bancali Raffi Cormano', value: 0, route: 'gestione' },
        { id: 13, icon: 'archive', name: 'Bancali Raffi Paderno', value: 0, route: 'gestione' },
        { id: 14, icon: 'archive', name: 'Bancali Panini Cormano', value: 0, route: 'gestione' },
        { id: 15, icon: 'archive', name: 'Bancali Panini Paderno', value: 0, route: 'gestione' },
        { id: 16, icon: 'archive', name: 'Bancali Panini Milano Cormano', value: 0, route: 'gestione' },
        { id: 17, icon: 'archive', name: 'Bancali Panini Milano Paderno', value: 0, route: 'gestione' }
      ]
    }
  },
  methods: {
    getBoxParam (boxNumber, param) {
      return this.boxes[boxNumber][param]
    }
  },
  mounted () {
    this.$plsqlMethod('ut', 'get_totals', {})
      .then(response => {
        this.boxes[0].value = response.data.clienti
        this.boxes[1].value = response.data.tipi_lavorazione
        this.boxes[2].value = response.data.componenti
        this.boxes[3].value = response.data.corrieri
        this.boxes[4].value = response.data.ordini_nuovi
        this.boxes[4].name = this.boxes[4].name + ' ' + response.data.esercizio
        this.boxes[5].value = response.data.ordini_spediti
        this.boxes[5].name = this.boxes[5].name + ' ' + response.data.esercizio
        this.boxes[6].value = response.data.ordini_spediti + response.data.ordini_nuovi
        this.boxes[6].name = this.boxes[6].name + ' ' + response.data.esercizio
        this.boxes[7].value = response.data.ddt
        this.boxes[7].name = this.boxes[7].name + ' ' + response.data.esercizio
        this.boxes[8].value = response.data.bancalic
        this.boxes[9].value = response.data.bancalip
        this.boxes[10].value = response.data.rbac || 0
        this.boxes[11].value = response.data.rbap || 0
        this.boxes[12].value = response.data.raffic || 0
        this.boxes[13].value = response.data.raffip || 0
        this.boxes[14].value = response.data.paninic || 0
        this.boxes[15].value = response.data.paninip || 0
        this.boxes[16].value = response.data.paninimc || 0
        this.boxes[17].value = response.data.paninimp || 0

        // if (response.data.check_bancali_prelievi && (response.data.check_bancali_prelievi > 0 || response.data.check_qta_bancali > 0 || response.data.check_qta_componenti > 0)) {
        if (response.data.check_bancali_prelievi && (response.data.check_bancali_prelievi > 0 || response.data.check_qta_bancali > 0)) {
          this.$http({
            method: 'post',
            url: `/mail/alert`
          }).then(() => {
            $EventBus.$emit('message', { type: 'success', text: 'Mail alert inviata!' })
          }).catch((err) => {
            console.log(err)
            $EventBus.$emit('message', { type: 'error', text: 'Impossibile inviare la mail di alert per i conteggi db' })
          })
        }
      })
      .catch(err => {
        $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
      })
  }
}
</script>

<style scoped>
  .w50 {
    width: 50%;
  }
</style>
